<script lang="ts" setup>
const navigationItems = [
  {
    title: 'Kategorie',
    href: '/kategorie',
  }, {
    title: 'Realizace',
    href: '/realizace',
  }, {
    title: 'O Nás',
    href: '/o-nas',
  }, {
    title: 'Kontakt',
    href: '/kontakt',
  },
]
const socialItems = [
  {
    icon: 'i-bxl-facebook-circle',
    href: 'https://www.facebook.com/',
  },
  {
    icon: 'i-bxl-instagram',
    href: 'https://www.instagram.com',
  },
  {
    icon: 'i-bxl-twitter',
    href: 'https://www.twitter.com',
  },
]
</script>

<template>
  <div flex flex-col gap-32px py-48px>
    <div flex items-center justify-center font-inter>
      <NuxtLink v-for="item in navigationItems" :key="item.href" :to="item.href" px-20px py-8px class="text-14px lg:text-16px text-app-text-gray-light" leading-24px font-regular>
        {{ item.title }}
      </NuxtLink>
    </div>
    <div flex items-center justify-center gap-24px>
      <NuxtLink v-for="item in socialItems" :key="item.href" :to="item.href" external target="_blank">
        <Icon :name="item.icon" text="24px app-text-gray-icon" />
      </NuxtLink>
    </div>
    <p text="14px lg:16px app-text-light center">
      © 2024 Kovotas s.r.o. - Všechna práva vyhrazena
    </p>
  </div>
</template>
